import React, { useState } from "react";
import style from "./ContactUs.module.css";
import { useTranslation } from "react-i18next";
import { addConatactus } from "../../../service/Apis";
import Loader from "../../../components/Loader/Loader";
import Toaster from "../../../components/Toaster";

const ContactUs = () => {
  let { t } = useTranslation();
    const [loader, setLoader] = useState(false);
  const [formData,setFormData] = useState({
    name: "",
    phone:"",
    message:"",
    email:"",
  })
   const [toaster, setToaster] = useState({
      show: false,
      message: "",
    });
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (value === " ") {
      e.target.value = "";
    } else {
      setFormData({
        ...formData,
        [name]: value
      });
    }

  };

  const contactSubmitForm = async (e) => {
    e.preventDefault();
    try{
      setLoader(true);
      let payload ={...formData}
      let response = await addConatactus(payload);
      setToaster({
        show: true,
        message: "Constact send successfully",
      });
      setFormData({  name: "",
        phone:"",
        message:"",
        email:"",})

    }catch(err){
      console.log(err)
    }finally{
      setLoader(false);
    }
  }
  return (
    <div>
       <Toaster
              event={toaster.message}
              show={toaster.show}
              severity={"success"}
              setToaster={setToaster}
            />
      <Loader open={loader} />
      <div className={`${style.banner}`}>
        <img src="/images/contactus.png" className="w-100" alt="" />
        <div className={`${style.banner_abolute}`}>
          <div className={`${style.lufga_36px_style}`}>{t("Contact Us")}</div>
          <div className={`${style.lufga_18px_style}`}>
            {t("Committed to serve you better in your parenthood journey")}
          </div>
        </div>
      </div>

      <div className={style.main_content}>
        <div className={style.main_img}>
          <img
            className={style.main_img_tag}
            src="/images/contact_us.png"
            alt="couple and child"
          />
        </div>
        <div className={`d-flex flex-column ${style.right_main_container}`}>
          <div className={`${style.free_consultant_request} lufga_24px fw-600 mt-3`}>{t("Free Consultant Request")}</div>
          <div className={style.right_container}>
            <div className={style.right_inner}>
              <div className={style.input_form_main}>
                <form onSubmit={contactSubmitForm}>
                <div className={style.input_form}>
                  <label
                    for="fname"
                    className={`lufga_20px ${style.label_input}`}
                  >
                    {t("Full Name")}
                  </label>
                  <input className="mt-4" required name="name" type="text" value={formData.name} id="fname" onChange={handleInputChange}/>
                  <label
                    for="lname"
                    className={`lufga_20px mt-4 ${style.label_input}`}
                  >
                    {t("Email")}
                  </label>
                  <input className="mt-4" required type="email" id="lname" value={formData.email} name="email" onChange={handleInputChange} />
                  <label
                    for="lname"
                    className={`lufga_20px mt-4 ${style.label_input}`}
                  >
                    {t("Phone Number")}
                  </label>
                  <input className="mt-4" required type="number" id="lname" value={formData.phone} name="phone" onChange={handleInputChange} />
                  <label
                    for="msg"
                    className={`lufga_20px mt-4 ${style.label_input}`}
                  >
                    {t("Message")}
                  </label>
                  <input className="mt-4" required type="text" id="msg" value={formData.message} name="message" onChange={handleInputChange}/>
                </div>
                <button className={style.btn} type="submit">{t("Send")}</button>
                </form>
              </div>
              <div className={style.right_div}>
                <div className={`${style.right_one}`}>
                  <div className={`lufga_24px fw-600`}>{t("Contact")}</div>
                  <div className={`lufga_18px d-flex`}>
                    info@newhopepoints.org newhopepoints@gmail.com
                  </div>

                  <div className={`lufga_24px fw-600`}>{t("Based in")}</div>

                  <div className={`lufga_18px`}>
                    {t("2469 FM 359 Rd. S, Ste X Brookshire, TX 77423, USA")}
                  </div>
                  <div className={`pt-5 mt-5 ${style.link_section}`}>
                    <img src="/images/facebool_black.png" alt="facebook logo" />
                    <img
                      src="/images/instagram_black.png"
                      alt="facebook logo"
                    />
                    <img src="/images/twitter_black.png" alt="facebook logo" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactUs;
