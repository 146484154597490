import axios from "axios";
import Endpoint, {
  forgetPassword,
  forgetPasswordSentMail,
  getUser,
  services,
  surrogate,
  embryoDonor,
  uploadDocs,
  admin,
  baseUrl,
  news,
  adoptingParent,
  intendedParent,
  contact
} from "./Endpoint";



let userdata = JSON.parse(localStorage.getItem("userData"))

let config = {
    headers: {
        Authorization: `Bearer ${userdata?.token}`,
    }
  }


// service apis start
export const sendOptOnPhoneNumber = async (data) => {
  return await axios.post(`${Endpoint.baseUrl}${services.sendOtp}`, data);
};

export const verifyOptByPhoneNumber = async (data) => {
  return await axios.post(`${Endpoint.baseUrl}${services.verifyOtp}`, data);
};

export const getUserDataById = async (id) => {
  return await axios.get(`${Endpoint.baseUrl}${getUser}${id}`,config);
};

export const verifyPhoneNumber = async (id, data) => {
  return await axios.put(
    `${Endpoint.baseUrl}${services.verifyPhoneNumber}${id}`,
    data
  );
};

export const verifyEmailAddress = async (data) => {
  return await axios.put(`${Endpoint.baseUrl}${services.verifyEmail}`, data);
};

export const verifyEmail = async (data) => {
  return await axios.post(`${Endpoint.baseUrl}${services.emailVerify}`, data);
};

export const uploadDocument = async (body) => {
  return await axios.post(`${Endpoint.baseUrl}${services.uploadDocs}`, body);
};
export const uploadMultipleFiles = async (files) => {
  return await axios.post(
    `${Endpoint.baseUrl}${services.uploadMultiDocs}`,
    files
  );
};

// service apis end

export const signUpUser = async (data) => {
  try {
    return await axios.post(`${Endpoint.baseUrl}${Endpoint.signUp}`, data);
  } catch (err) {
    return err;
  }
};

export const LogInUser = async (data) => {
  try {
    return await axios.post(
      `${Endpoint.baseUrl}${Endpoint.logInDashboard}`,
      data
    );
  } catch (err) {
    return err;
  }
};

export const forgetPasswordEmailSend = async (data) => {
  return await axios.post(`${Endpoint.baseUrl}${forgetPasswordSentMail}`, data);
};

export const forgetPasswordChange = async (id, data) => {
  return await axios.put(`${Endpoint.baseUrl}${forgetPassword}${id}`, data);
};

export const getResumeData = async (userId) => {
  return await axios.get(
    `${Endpoint.baseUrl}${surrogate.getResumeData}${userId}`
  );
};

export const resumeForm1 = async (userId, body) => {
  return await axios.post(
    `${Endpoint.baseUrl}${surrogate.addForm1}${userId}`,
    body
  );
};
export const resumeForm2 = async (userId, body) => {
  return await axios.post(
    `${Endpoint.baseUrl}${surrogate.addForm2}${userId}`,
    body
  );
};
export const resumeForm3 = async (userId, body) => {
  return await axios.post(
    `${Endpoint.baseUrl}${surrogate.addForm3}${userId}`,
    body
  );
};

export const resumeForm4 = async (userId, body) => {
  return await axios.post(
    `${Endpoint.baseUrl}${surrogate.addForm4}${userId}`,
    body
  );
};
export const resumeForm5 = async (userId, body) => {
  return await axios.post(
    `${Endpoint.baseUrl}${surrogate.addForm5}${userId}`,
    body
  );
};
export const resumeForm6 = async (userId, body) => {
  return await axios.post(
    `${Endpoint.baseUrl}${surrogate.addForm6}${userId}`,
    body
  );
};
export const resumeForm7 = async (userId, body) => {
  return await axios.post(
    `${Endpoint.baseUrl}${surrogate.addForm7}${userId}`,
    body
  );
};
export const resumeForm8 = async (userId, body) => {
  return await axios.post(
    `${Endpoint.baseUrl}${surrogate.addForm8}${userId}`,
    body
  );
};
export const resumeForm9 = async (userId, body) => {
  return await axios.post(
    `${Endpoint.baseUrl}${surrogate.addForm9}${userId}`,
    body
  );
};
export const resumeForm10 = async (userId, body) => {
  return await axios.post(
    `${Endpoint.baseUrl}${surrogate.addForm10}${userId}`,
    body
  );
};
export const resumeForm11 = async (userId, body) => {
  return await axios.post(
    `${Endpoint.baseUrl}${surrogate.addForm11}${userId}`,
    body
  );
};

export const deleteDocs = async (body) => {
  return await axios.post(`${Endpoint.baseUrl}${services.deleteDocs}`, body);
};

export const getSocialPage = async () => {
  return await axios.get(`${Endpoint.baseUrl}${admin.getSocialPage}`);
};

export async function getAllNews(){
  return await axios.get(`${baseUrl}${news.getNews}`,config)
}


export async function addFormConcise1(id,data){
  return await axios.post(`${baseUrl}${surrogate.conciseFormOne}${id}`,data)
}

export async function addFormConcise2(id,data){
  return await axios.post(`${baseUrl}${surrogate.conciseFormtwo}${id}`,data)
}

export async function getConciseFormData(id){
  return await axios.get(`${baseUrl}${surrogate.conciseFormData}${id}`)
}
export const getlifestyledata=async() =>{
  return await axios.get(`${baseUrl}${admin.getAllLifestyle}`,config)
}
export const gethabitsdata=async() =>{
  return await axios.get(`${baseUrl}${admin.getAllHabits}`,config)
}

export const addEmbryoMother = async (userId, body) => {
  return await axios.post(
    `${Endpoint.baseUrl}${embryoDonor.addEmbryoMother}${userId}`,
    body
  );
}; 

export const addEmbryoFather = async (userId, body) => {
  return await axios.post(
    `${Endpoint.baseUrl}${embryoDonor.addEmbryoFather}${userId}`,
    body
  );
};  

export async function getEmbryoDonorFormData(id){
  return await axios.get(`${baseUrl}${embryoDonor.embryoDonorFormData}${id}`)
}

export async function getEmbryoDonorFormDataByUserId(id){
  return await axios.get(`${baseUrl}${embryoDonor.getEmbryoDonorFormDataByUserId}${id}`)
}


export const addAdoptingParent = async (userId, body) => {
  return await axios.post(
    `${Endpoint.baseUrl}${adoptingParent.addAdoptingParent}${userId}`,
    body
  );
}; 

export const getAdoptingParentFormData = async (id) => {
  return await axios.get(`${baseUrl}${adoptingParent.getAdoptingParentById}${id}`)
}

export const addIntendedParentFormData = async (userId, body) => {
  return await axios.post(
    `${Endpoint.baseUrl}${intendedParent.addIntendedParent}${userId}`,
    body
  );
}; 

export const getIntendedParentsFormData = async (id) => {
  return await axios.get(`${baseUrl}${intendedParent.getIntendedParentById}${id}`)
}

export const addConatactus = async(body) =>{
  return await axios.post(`${baseUrl}${contact.contactUs}`,body)
}


